// @font-face {
//   font-family: 'Raleway';
//   src: url('../src/assets/styles/fonts/Raleway/Raleway.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
body {
  font-family: 'Raleway', sans-serif;
}

html {
  scroll-behavior: smooth;
  padding: 0;
  border: 0;
  font-family: 'Raleway';
  // overflow-x: hidden !important;

  #root{
    overflow-x: hidden !important;
  }
}

.desktop{
  @media (max-width: 991px) {
      display: none !important;
  }
}

.mobile{
  @media (min-width: 992px) {
    display: none !important;
  }
}


.navbar-toggler {
  border: none;
  padding: 0;

  &:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}
}

.navbar-toggler-icon{
  background-image: none;
  background: url("../src/assets/icons/menu.png") no-repeat, transparent;
  border: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0.8999999761581421 !important;
  // background: #F25C05 !important;
  background: #555;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  opacity: 0.8999999761581421 !important;
  background: #FFF;
  // border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F25C05;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFF;
  color: #011F26;
  margin-top: 110px;

  @media (max-width: 991px) {
    margin-top: 70px;
  }

  .input-group-btn{
    position: relative;

    input{
      padding-right: 36px;
    }

    button{
      position: absolute;
      top: 8px;
      right: 0;
      outline: none !important;
      border-radius: 50%;
    }
  }

  .navbar{
    // opacity: 0.8999999761581421 !important;
    // background: #FFF !important;
    color: var(--primary, #F25C05);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    height: auto;
    min-height: 48px;

    @media (max-width: 991px) {
      min-height: 70px;
    }
    
    .menu-container{
      max-width: 100%;
      display: flex;
      // width: 1440px;
      padding: 24px 120px;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 991px) {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        img{
          // max-width: 95px;
          max-height: 48px;
          margin: auto;
        }

        svg{
          max-width: 95px;
          margin: auto;
        }

        .navbar-brand{
          // margin: auto;
          // margin-left: 60px;
        }
      }

      .links-container{
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .link-container{
          min-width: 157px;
          text-align: center;

          a{
            margin: auto;
            color: var(--primary, #F25C05);
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      .menus-container{
        vertical-align: middle;

        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        text-align: right;
        gap: 40px;
        max-width: 360px;

        @media (max-width: 991px) {
          display: block;
        }

        a{
          white-space: nowrap;
          width: 100%;
          max-width: 157px;

          margin: auto;
          color: var(--primary, #F25C05);
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          @media (max-width: 991px) {
            max-width: 100%;
          }
        }

        .social-networks-container{
          vertical-align: middle;
          margin-left: 5%;
          // margin: auto;
  
          width: 100%;
          max-width: 157px;
          display: flex;
          // flex-wrap: wrap;
          align-items: flex-end;
          justify-content: flex-end;
          text-align: right;
  
          @media (max-width: 991px) {
            // max-width: 100%;
            margin: auto;
          }

          .social-network{
            margin: auto;
            margin-top: -30px;

            @media (max-width: 991px) {
              margin-top: 30px;
              margin-bottom: 30px;
            }
            // img{
  
            // }
          }
        }
      }
    }

    .navbar-brand{
      img{
        // border-radius: 50%;
        height: 48px;
      }
    }

    .menu-items{
      display: flex;
      align-items: self-start;
      width: 100%;
    }

    .navbar-nav {
      display: flex;
      align-items: self-end;
      justify-content: flex-end;
      width: 100%;

      @media (max-width: 991px) {
        align-items: start;
        justify-content: flex-start;
      }
    }

    .nav-link{
      // color: #FFF !important;
      // text-align: center;
      // font-size: 16px;
      // font-family: Raleway;
      // font-style: normal;
      // font-weight: 600;
      // line-height: normal;
      // letter-spacing: -0.32px;
      // margin-right: 20px;

      color: var(--primary, #F25C05);
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .first-section{
    height: 675px;
    // background: #BBE8F2;
    background: linear-gradient(0deg, #FAFAFA 100%, #FFF 100%);
    width: 100%;
    position: relative;

    @media (max-width: 991px) {
      height: 720px;
    }

    .container-left{
      position: absolute;
      top: 98px;
      left: 120px;
      z-index: 2;

      @media (max-width: 991px) {
        position: initial;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 120px;
      }

      .text{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        max-width: 483px;
        margin-bottom: 8px;

        @media (max-width: 991px) {
          font-size: 38px;
          margin-bottom: 16px;
        }
        
        &.smaller{
          color: #797979;
          font-family: Raleway;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 100%;
          max-width: 573px;

          @media (max-width: 991px) {
            color: #797979;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
        }
      }

      .button-container{
        padding-top: 42px;
        margin-bottom: 42px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 991px) {
          padding-top: 0;
          margin-bottom: 22px;
          flex-direction: column;
        }

        button{
          display: flex;
          width: 100%;
          max-width: 358px;
          height: 56px;
          min-height: 56px;
          padding: 8px 34px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--primary, #F25C05);
          text-decoration: none;
          border: none;
  
          color: #FFF;
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          @media (max-width: 991px) {
            margin-top: 10px;
            margin-left: 0px;
          }
        }

        a {
          display: flex;
          width: 100%;
          max-width: 358px;
          height: 56px;
          min-height: 56px;
          padding: 8px 34px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--primary, #F25C05);
          text-decoration: none;
          border: none;
  
          color: #FFF;
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .availability-container{

        @media (max-width: 991px) {
          width: 100%;
          text-align: center;
          margin-bottom: 24px;
          margin-left: -24px;
        }

        .availability{
          &.desktop{
            width: 170px;
          }
          &.mobile{
            width: 260px;
          }
        }
        // @media (max-width: 991px) {
        //   img{
        //     width: 153px;
        //   }
        // }
      }
    }

    .calendar{
      position: absolute;
      right: 0;
      top: 10px;
      max-width: 40%;
      // bottom: 0;
      // z-index: 1;
      // width: 100%;
      // max-width: 1199px;
      // max-height: 554px;

      @media (max-width: 991px) {
        width: 100%;
        // max-height: 265px;
        // max-width: 1199px;
        right: 0px;
        top: unset;
        max-height: 398px;
        max-width: 324px;
      }

      // @media(min-width: 1200px) {
      //   .container-special-width(95%);
      //   margin-bottom: 5px;
      // }
    }
  }

  .second-section{
    background: #FFF;
    // height: 1630px;
    width: 100%;
    text-align: center;

    .button-container{
      text-align: left;
      display: flex;
      justify-content: space-between;
     
      @media (max-width: 991px) {
        flex-direction: column;
        text-align: center;
        width: 100%;
      }

      button{
        display: flex;
        width: 100%;
        max-width: 358px;
        height: 56px;
        min-height: 56px;
        padding: 8px 34px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        background: var(--primary, #F25C05);
        text-decoration: none;
        border: none;

        color: #FFF;
        text-align: center;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 991px) {
          margin-top: 10px;
          margin-left: 0px;
          max-width: 100%;
        }
      }
    }

    .smaller-container{
      padding-left: 120px;
      padding-right: 120px;
      padding-top: 197px;

      @media (max-width: 991px) {
        padding-left: 24px;
        padding-right: 24px;
      }

      .tutorial-container{
        width: 100%;
  
        .tutorial-row{
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          
          .tutorial{
            display: flex;
            margin-bottom: 261px;
            
            @media (max-width: 991px) {
              flex-direction: column-reverse;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-around;
              margin-bottom: 64.57px;
            }
  
            &.whole{
              width: 100%;
              margin-bottom: 103px;
            }
    
            .left{
              color: #F25C05;
              /* Land-text-titulo-descricao */
              font-size: 20px;
              font-family: Raleway;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
    
              display: flex;
              align-items: flex-start;
              justify-content: left;
              vertical-align: middle;
              // width: 100%;
              // margin: auto;
              
              .image{
                padding-top: 80px;
                // overflow-y: hidden;
                // height: 252px;
                @media (max-width: 991px) {
                  width: 100%;
                  padding-top: 0;
                }

                img{
                  @media (max-width: 991px) {
                    width: 100%;
                  }
                }

              }
            }
    
            .right{
              padding-top: 39px;
              padding-bottom: 22px;
              padding-left: 45.67px;
              text-align: left;
              color:#0D1F23;
              /* Land-text-descricao */
              
              margin: auto;
              
              @media (min-width: 991px) {
                margin-left: 0;
              }
  
              @media (max-width: 991px) {
                text-align: left;
                padding-left: 0px;
                padding-bottom: 0;
                // padding-right: 50px;
              }
  
              .title{
                color: var(--dark, #011F26);
                font-family: Raleway;
                font-size: 38px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 17px;
                max-width: 432px;

  
                @media (max-width: 991px) {
                  color: var(--dark, #011F26);
                  font-family: Raleway;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding-bottom: 16px;
                }
              }
    
              .text{
                color: #797979;
                font-family: Raleway;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 32px;
                max-width: 705px;
  
                @media (max-width: 991px) {
                  width: 100%;
                  color: #797979;
                  font-family: Raleway;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  padding-bottom: 16px;
                  margin-bottom: 0;
                }
  
                &.whole{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .title-container{
      padding-top: 50px;
      margin-bottom: 50px;

      @media (max-width: 991px) {
        padding-top: 50px;
        margin-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
        font-size: 40px;
      }

      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;

      color: #6095A0;
      text-align: center;

      /* Land-titulo-modulo */
      font-size: 56px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      &.dark{
        color: #0D1F23;
        margin-top: 0px;
        margin-top: -50px;

        @media (max-width: 991px) {
          padding-top: 50px;
          margin-bottom: 50px;
          // padding-left: 50px;
          // padding-right: 50px;
          font-size: 40px;
          margin-left: -16px;
        }
    }
    }

    .functionalities-container{
      display: flex;
      flex-wrap: wrap;
      // align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      .functionality{
        width: 100%;
        max-width: 313px;
        // margin: auto;

        margin-bottom: 16px;

        @media (max-width: 991px) {
          margin-bottom: 40px;
          max-width: 100%;
        }

        .top{
          color: #F25C05;
          /* Land-text-titulo-descricao */
          font-size: 20px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 300;
          line-height: normal;

          display: flex;
          align-items: flex-start;
          justify-content: left;
          vertical-align: middle;
          width: 100%;
          margin-bottom: 24px;

          @media (max-width: 991px) {
            margin-bottom: 12px;
          }

          div{
            margin-left: 4px;

            &.title{
              margin: auto;
              margin-left: 21px;
              text-align: left;
              min-height: 40px;
              text-align: left;
              min-height: 40px;
            }
          }
        }
        .bottom{
          text-align: left;
          color:#0D1F23;
          /* Land-text-descricao */
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.54px;
        }
      }
    }

    .big-text{
      text-align: left;
      color:#0D1F23;
      /* Land-text-descricao */
      font-size: 18px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.54px;
      margin-bottom: 40px;

      @media (max-width: 991px) {
        display: none;
        margin-bottom: 0px;
      }
    }

    

    .print-screens{
      width: 100%;
      margin-bottom: 52px;

      @media (max-width: 991px) {
        margin: auto;
        margin-bottom: 52px;
        width: 100%;
        max-width: 300px;
      }
    }
  }

  .third-section{
    position: relative;
    margin: auto;
    
    display: flex;

    @media (max-width: 991px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .right-side{
      @media (max-width: 991px) {
        max-width: 100%;
      }

      max-width: 50%;
      img{
        // width: 714px;
        // min-height: 684px;
        width: 100%;
        flex-shrink: 0;
      }
    }

    .left-side{
      width: 100%;
      // max-width: 714px;
      max-width: 50%;
      padding-left: 120px;

      @media (max-width: 991px) {
        max-width: 100%;
        padding-left: 24px;
        padding-right: 30px;
        padding-top: 65.07px;
      }

      background: #F9A983;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .title{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        max-width: 458px;
        margin-bottom: 32px;

        @media (max-width: 991px) {
          color: var(--dark, #011F26);
          font-family: Raleway;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 16px;
          max-width: 100%;
          width: 100%;
        }
      }
      .subtitle{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        max-width: 458px;
        margin-bottom: 52px;

        @media (max-width: 991px) {
          color: var(--dark, #011F26);
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          margin-bottom: 16px;
          max-width: 100%;
          width: 100%;
        }
      }
      
      .button-container{
        padding-bottom: 50px;
        text-align: left;
  
        @media (max-width: 991px) {
          padding-bottom: 83px;
          // 
        }
  
        button{
          width: 100%;
          max-width: 358px;
          height: 56px;
          flex-shrink: 0;
          border-radius: 10px;
          color: #FFF;
          border: none;
          background: #F25C05;
          text-decoration: none;
  
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
  
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }
    }
  }

  .fourth-section{
    background: #FFF;
    // height: 1630px;
    width: 100%;
    text-align: center;
    padding-top: 115px;
    padding-left: 120px;
    padding-right: 120px;

    @media (max-width: 991px) {
      max-width: 100%;
      padding-left: 24px;
      padding-right: 30px;
      padding-top: 80px;
      text-align: left;
    }

    .smaller-container{
      // width: 1198px;
      img{
        width: 100%;
        height: auto;
        flex-shrink: 0;

        @media (min-width: 991px) {
          width: 100%;
          height: auto;
          max-width: 1440px;
        }
      }
    }

    .title-container{
      color: var(--dark, #011F26);
      text-align: center;
      font-family: Raleway;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      max-width: 1038px;
      margin: auto;
      margin-bottom: 30px;

      @media (max-width: 991px) {
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 16px;
        text-align: left;
      }
    }

    .subtitle-container{
      color: #797979;
      text-align: center;
      font-family: Raleway;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      max-width: 1038px;
      margin: auto;
      margin-bottom: 50px;

      @media (max-width: 991px) {
        color: #797979;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-bottom: 24px;
        text-align: left;
      }
    }
  }

  .fifth-section{
    position: relative;
    margin: auto;
    background: var(--dark, #011F26);
    
    display: flex;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .button-container{
        padding-bottom: 216px;
        text-align: left;
  
        @media (max-width: 991px) {
          padding-bottom: 80px;
          width: 100%;
          padding-left: 24px;
          padding-right: 24px;
        }
  
        button{
          width: 100%;
          max-width: 358px;
          height: 56px;
          flex-shrink: 0;
          border-radius: 10px;
          color: #FFF;
          border: none;
          background: #F25C05;
          text-decoration: none;
  
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
  
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }

    .right-side{
      min-width: 50%;
      max-width: 50%;
      
      @media (max-width: 991px) {
        max-width: 289px;
        width: 100%;
        margin: auto;
        margin-bottom: 40px;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      
      img{
        // width: 714px;
        // min-height: 684px;
        width: 100%;
        max-width: 289px;
        flex-shrink: 0;
        margin: auto;
        @media (max-width: 991px) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .left-side{
      width: 100%;
      // max-width: 714px;
      min-width: 50%;
      max-width: 50%;
      padding-top: 194px;
      padding-left: 120px;
      padding-right: 120px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      @media (max-width: 991px) {
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 80px;
      }

      .title{
        color: var(--white, #FFF);
        font-family: Raleway;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;

        @media (max-width: 991px) {
          color: var(--white, #FFF);
          font-family: Raleway;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 16px;
        }
      }
      .subtitle{
        color: var(--white, #FFF);
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 52px;

        @media (max-width: 991px) {
          color: #FFF;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          margin-bottom: 40px;
        }
      }
    }
  }

  .sixth-section{
    background: #FFF;
    // height: 1630px;
    width: 100%;
    text-align: center;

    .smaller-container{
      padding-left: 120px;
      padding-right: 120px;

      @media (max-width: 991px) {
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 80px;
      }

      .tutorial-container{
        width: 100%;
  
        .tutorial-row{
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          
          .tutorial{
            display: flex;
            margin-bottom: 139px;
            
            @media (max-width: 991px) {
              margin-bottom: 16px;
              flex-direction: column-reverse;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-around;
              margin-bottom: 80px;
            }
  
            &.whole{
              width: 100%;
              margin-bottom: 103px;
            }
    
            .left{
              color: #F25C05;
              /* Land-text-titulo-descricao */
              font-size: 20px;
              font-family: Raleway;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
    
              display: flex;
              align-items: flex-start;
              justify-content: left;
              vertical-align: middle;

              @media (max-width: 991px) {
                // padding-top: 80px;
                width: 100%;
              }
              // margin: auto;
              
              .image{
                padding-top: 78px;

                @media (max-width: 991px) {
                  // padding-top: 80px;
                  width: 100%;
                  max-width: 281.676px;
                  height: auto;
                  margin: auto;
                }
                
                img{
                  width: 371px;
                  height: 711px;
                  flex-shrink: 0;

                  @media (max-width: 991px) {
                    width: 100%;
                    max-width: 281.676px;
                    height: auto;
                    margin: auto;
                  }
                }
                // overflow-y: hidden;
                // height: 252px;

                @media (max-width: 991px) {
                  width: 100%;
                }
              }
            }
    
            .right{
              padding-top: 39px;
              padding-bottom: 22px;
              padding-left: 45.67px;
              text-align: left;
              color:#0D1F23;
              /* Land-text-descricao */
              
              margin: auto;
              
              @media (min-width: 991px) {
                margin-left: 0;
                padding-bottom: 0;
              }
  
              @media (max-width: 991px) {
                padding-top: 0;
                padding-left: 0px;
              }
  
              .title{
                color: var(--dark, #011F26);
                font-family: Raleway;
                font-size: 38px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 10px;
                max-width: 657px;
  
                @media (max-width: 991px) {
                  padding-bottom: 16px;
                  margin-bottom: 0;
                  color: var(--dark, #011F26);
                  font-family: Raleway;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
    
              .text{
                color: #797979;
                font-family: Raleway;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 32px;
                max-width: 657px;

  
                @media (max-width: 991px) {
                  width: 100%;
                  color: #797979;
                  font-family: Raleway;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  margin-bottom: 16px;
                }
  
                &.whole{
                  width: 100%;
                }
              }
    
              .button-container{
                text-align: left;
                display: flex;
                justify-content: space-between;
               
                @media (max-width: 991px) {
                  flex-direction: column;
                  text-align: center;
                }
          
                button{
                  display: flex;
                  width: 100%;
                  max-width: 358px;
                  height: 56px;
                  min-height: 56px;
                  padding: 8px 34px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 8px;
                  background: var(--primary, #F25C05);
                  text-decoration: none;
                  border: none;
          
                  color: #FFF;
                  text-align: center;
                  font-family: Raleway;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
        
                  @media (max-width: 991px) {
                    margin-top: 10px;
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .seventh-section{
    background: #F6F6F6;
    // height: 1630px;
    width: 100%;
    text-align: center;
    padding-top: 112px;
    padding-bottom: 202px;
    padding-left: 120px;

    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 207.55px;
      padding-left: 24px;
      // padding-right: 24px;
    }

    .smaller-container{
      // width: 1198px;
      img{
        width: 100%;

        @media (min-width: 991px) {
          width: 100%;
          min-height: 713px;
        }
        flex-shrink: 0;
      }
    }

    .carousel{

      .slick-slide > div {
        margin: 0 25px; /* Half of 50px on each side for consistent spacing */
      }
      
      .slick-list {
        margin: 0 -25px; /* To counter the margin so the first and last items align correctly */
      }

      .slick-arrow{
        top: 130%;

        width: 48px;
        height: 48px;

        @media (max-width: 991px) {
          top: 300px;

          width: 48px;
          height: 48px;
        }

        img{
          width: 48px;
          height: 48px;

          @media (max-width: 991px) {
            width: 48px;
            height: 48px;
          }
        }
      }

      .slick-prev{
        left: 45%;
        z-index: 10;
        
        @media (max-width: 991px) {
          left: 30%;
        }

        &::before{
          content: none;
        }
      }

      .slick-next{
        right: 45%;
        z-index: 10;

        @media (max-width: 991px) {
          right: 40%;
        }

        &::before{
          content: none;
        }
      }

      img{
        width: 100%;
        max-width: 533.333px;
        // height: 300px;
        height: auto;
        flex-shrink: 0;

        @media (max-width: 991px) {
          width: 311.024px;
          height: auto;
        }
      }
    }

    .title-container{
      color: var(--dark, #011F26);
      text-align: center;
      font-family: Raleway;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      max-width: 1038px;
      margin: auto;
      margin-bottom: 30px;

      @media (max-width: 991px) {
        margin-bottom: 32px;
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;

      }
    }
  } 

  .footer{
    background: #FFF;
    padding-top: 125px;

    @media (max-width: 991px) {
      background-size: cover;
    }

    .footer-content{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 143.3px;

      @media (max-width: 991px) {
        flex-wrap: wrap;
        padding-bottom: 80px;
      }


      .footer-content-left{
        padding-left: 120px;

        @media (max-width: 991px) {
          padding-left: 24px;
          padding-right: 24px;
          flex-wrap: wrap;
          margin-bottom: 44.2px;
          width: 100%;
        }

        .top-container{
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-around;
          width: 100%;
          // max-width: 510px;
          margin: auto;
          margin-bottom: 19px;
    
          @media (max-width: 991px) {
            flex-wrap: wrap;
            // max-width: 257px;
          }
    
          .logo-container{
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
    
            img{
              width: 188px;
              margin: auto;
            }
    
            @media (max-width: 991px) {
              margin-bottom: 40px;
            }
          }
    
          .text-container{
            width: 100%;
            margin: auto;
            color: var(--dark, #011F26);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            flex-direction: column;
    
            @media (max-width: 991px) {
              display: flex;
              flex-direction: column;
              max-width: unset;
            }
    
            b{
              width: 100%;
              margin: auto;
              color: var(--dark, #011F26);
              font-family: Raleway;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              display: flex;
              flex-direction: column;
            }
          }
        }
    
        .bottom-container{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          max-width: 865px;
          margin: auto;
    
          .social-networks-container{
            width: 100%;
            max-width: 226px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
    
            color: var(--primary, #F25C05);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
    
            &.top{
              margin-bottom: 12.64px;
            }
    
            &.bottom{
              margin-left: -12px;
            }
    
            .social-network{
              margin: auto;
            }
          }
        }
      }
      .footer-content-right{
        padding-right: 168px;

        @media (max-width: 991px) {
          padding-left: 24px;
          padding-right: 24px;
          margin: auto;
          margin-left: 0;
        }

        .right-content-container{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          max-width: 865px;
          margin: auto;
          gap: 35px;

          .separator{
            width: 0.5px;
            height: 149px;
            background: #DCDCDC;
          }

          .title-container{
            color: var(--primary, #F25C05);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-right: 6px;
          }

          .links-container{
            padding-top: 12px;

            .link-container{
              a{
                color: var(--primary, #F25C05);
                font-family: Raleway;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: none;
              }
            }
          }

          .contacts-container{
            padding-top: 23px;

            .contact-container{
              display: flex;
              flex-direction: column;
              margin-bottom: 28px;

              color: var(--primary, #F25C05);
              font-family: Raleway;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              a{
                color: var(--primary, #F25C05);
                font-family: Raleway;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: none;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
                text-underline-position: from-font;
              }
            }
          }
        }
      }
    }

    .last-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // max-width: 865px;
      margin: auto;
      padding-top: 15px;
      padding-bottom: 15px;
      background: var(--primary, #F25C05);

      .date-company{
        width: 100%;
        // max-width: 150px;
        text-align: center;
        color: #FFF;
        margin: auto;

        /* TL-text-input */
        font-size: 14px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        white-space: nowrap;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
